import React from 'react';

import { Flex, RadioGroup, SimpleGrid, Radio } from '@chakra-ui/react';

import { maskMoney } from '../../../services/masks.service';
import Text from 'components/Text';

type SimulateProps = {
	handleSimulateOnChange: (obj: any) => void;
	radioValue: string;
	simulation: any;
};

const types = {
	credit: 'Crédito',
	pix: 'PIX',
	debit: 'Débito',
	pix_pos: 'PIX',
};

const Simulate: React.FC<SimulateProps> = (props: SimulateProps) => (
	<Flex direction='column' alignItems='start'>
		<Text fontSize='lg' fontWeight='medium' mb='4'>
			Forma de pagamento
		</Text>

		<RadioGroup w='100%' onChange={(event) => props.handleSimulateOnChange(event)} value={props.radioValue}>
			<SimpleGrid columns={2} justifyContent='space-between' w='100%' spacing='4'>
				{props.simulation.length > 0 &&
					props.simulation.map(({ installment, total_amount_cents, type, installment_amount_cents, cet }) => (
						<Radio
							key={`${type}${installment}`}
							value={JSON.stringify({
								installment: `${installment}x`,
								installmentAmount: installment_amount_cents / 100,
								totalAmount: total_amount_cents / 100,
								type: type,
								cet: cet,
							})}
							size='lg'
							borderColor='darkGrey'
							className={type === 'pix' || type === 'debit' ? `checkbox__${type}` : `checkbox__${type}-${installment}x`}
						>
							<Text fontSize='md' fontWeight='medium'>
								{`${types[type]}: ${installment}x de R$ ${maskMoney(installment_amount_cents / 100)}`}
							</Text>
						</Radio>
					))}
			</SimpleGrid>
		</RadioGroup>

		{props.simulation.length > 0 &&
			props.simulation.map((simulation) => simulation.installment).some((instalment) => [13, 14, 15, 16, 17, 18].includes(instalment)) && (
			<Text mt='6' fontSize='small' fontWeight='bold'>
				ATENÇÃO! Transações de 13 a 18X estão sujeitas a aprovação do banco emissor, não sendo a Parcela Express responsável diretamente
				por essa aprovação!
			</Text>
		)}
	</Flex>
);

export default Simulate;
