import React from 'react';

import { Formik } from 'formik';
import { Flex, Text, BreadcrumbItem, BreadcrumbLink, useBreakpointValue, Stack, Switch } from '@chakra-ui/react';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { useToasts } from 'react-toast-notifications';
import { useHistory } from 'react-router-dom';

import * as yup from 'yup';

import PixTable from './components/PixTable';
import Modal from './components/Modal';

import Loader from 'containers/Loader';

import BackofficeAlertContainer from 'containers/BackofficeAlertContainer';
import Breadcrumb from 'components/Breadcrumb';
import Title from 'components/Title';
import FormDatePickerInput from '../../components/Form/FormDatePickerInput';
import FormControl from 'components/Form/FormControl';
import FormLabel from 'components/Form/FormLabel';
import FormCurrencyInput from 'components/Form/FormCurrencyInput';
import FormInput from '../../components/Form/FormInput';
import PayerForm from 'containers/CreatePayerWrapper/PayerForm';
import SplitRulesWrapper, { SplitSellerValue } from 'containers/SplitRulesWrapper';
import Button from '../../components/Button';
import FormErrorMessage from '../../components/Form/FormErrorMessage';
import Paper from 'containers/Paper';

import {
	CreateSellerPayerRequest,
	SellersApi,
	CreateSplitRulesDto,
	CreatePixPaymentWithSplitDto,
	CreatePixPaymentWithSplitsRequest,
	PaymentsApi,
	InternalListSellerSalesRequest,
	PaginatedDto,
	SaleFormPaymentEnum,
	InternalListSellerSalesTypeEnum,
	ListSimulationRequest,
	SimulationApi,
} from '../../clients';

import { useAuth } from '../../contexts/AuthProvider';
import { useCurrentSeller } from '../../contexts/SellerProvider';

import { unmaskCpfOrCnpj } from 'services/masks.service';
import { getApiAuthConfig } from 'services/api.service';
import { round } from '../../services/math.service';
import { validSplitRulesAmount } from 'services/validations.service';
import { endOfDay } from 'date-fns';
import FormDebouncedInput from 'components/Form/FormDebouncedInput';
import * as math from 'mathjs';

const PixSale: React.FC = () => {
	const { user } = useAuth();
	const { currentSeller } = useCurrentSeller();
	const { addToast } = useToasts();

	const history = useHistory();

	const apiConfig = getApiAuthConfig();
	const sellerApi = new SellersApi(apiConfig);
	const simulationApi = new SimulationApi(apiConfig);

	const isMobile = useBreakpointValue({ base: true, lg: false });

	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
	const [pixListLoading, setPixListLoading] = React.useState<boolean>(false);
	const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);

	const [qrcode, setQrcode] = React.useState<string>('');

	const [pixSales, setPixSales] = React.useState<PaginatedDto>({} as PaginatedDto);

	const [updateTable, setUpdateTable] = React.useState(true);
	const [pagination, setPagination] = React.useState({ currentPage: 1, limit: 100 });
	const [pixSalesTotalPages, setPixSalesTotalPages] = React.useState(1);

	const [hasSplitRules, setHasSplitRules] = React.useState(false);
	const [splits, setSplits] = React.useState<SplitSellerValue[]>([]);
	const [showAmount, setShowAmount] = React.useState('');
	const [amount, setAmount] = React.useState('');

	const handleCreatePayerFormSubmit = async (values) => {
		try {
			setIsLoading(true);
			setIsSubmitting(true);

			const { payer } = values;

			const createPayerDto = payer;

			const createSellerPayerRequest: CreateSellerPayerRequest = {
				sellerId: currentSeller?.id!,
				createPayerDto,
			};

			await sellerApi.createSellerPayer(createSellerPayerRequest);
		} catch (error) {
			addToast('Dados Inválidos', {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
			setIsModalOpen(!isModalOpen);
		}
	};

	function navigateToDetails(id: string) {
		history.push(`/admin/sales-history/${id}`, { route: history.location.pathname });
	}

	const handleSubmit = async (values, isValid) => {
		try {
			setIsLoading(true);
			setIsSubmitting(true);

			await handleCreatePayerFormSubmit(values);

			if (!isValid) return;
			if (hasSplitRules && splits.length > 0) {
				const roundedAmount = round(values.amount * 100);
				const isSplitsValid = validSplitRulesAmount(roundedAmount, splits);
				if (!isSplitsValid) {
					addToast('A soma das divisões não podem ser maiores que o valor da venda', {
						appearance: 'error',
						autoDismiss: true,
					});
					return;
				}
			}

			const pixApiConfig = getApiAuthConfig();

			const parsedDeliveryDate = endOfDay(values.deliveryDate).toISOString();

			let createPix: CreatePixPaymentWithSplitDto = {
				amount_cents: round(Number(values.amount_cents) * 100),
				description: values.description,
				delivery_date: parsedDeliveryDate,
				customer: {
					first_name: values.payer.name.split(' ')[0],
					last_name: values.payer.name.split(' ').slice(1).join(' '),
					document: unmaskCpfOrCnpj(values.payer.document),
				},
			};

			const createPixPaymentRequest: CreatePixPaymentWithSplitsRequest = {
				sellerId: currentSeller?.id!,
				createPixPaymentWithSplitDto: createPix,
			};

			if (hasSplitRules && splits.length > 0) {
				const parsedSplits: CreateSplitRulesDto[] = splits.map((split) => ({ seller_id: split.seller.id, amount: split.amount }));
				createPixPaymentRequest.createPixPaymentWithSplitDto.has_split_rules = true;
				createPixPaymentRequest.createPixPaymentWithSplitDto.split_rules = parsedSplits;
			}

			const pixApi = new PaymentsApi(pixApiConfig);
			const response = await pixApi.createPixPaymentWithSplits(createPixPaymentRequest);

			if (response) {
				setIsModalOpen(!isModalOpen);
				setUpdateTable(!updateTable);
				setHasSplitRules(false);
				setSplits([]);
				setQrcode(response.qrCodeData);
				setIsLoading(false);
				setIsSubmitting(false);
			}

			addToast('Pix emitido com sucesso', {
				appearance: 'success',
				autoDismiss: true,
			});
		} catch (error) {
			addToast('Dados Inválidos', {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
			setSplits([]);
			setHasSplitRules(false);
		}
	};

	const pixInitialValues = {
		amount: '',
		description: '',
		deliveryDate: '',
		payer: {
			name: '',
			document: '',
			email: '',
			phone: '',
		},
	};

	const schema = yup.object().shape({
		description: yup.string().required('Campo obrigatório'),
		deliveryDate: yup.string().required('Campo obrigatório'),
		payer: yup.object().shape({
			name: yup.string().isCompleteName('Obrigatório nome completo').required('Campo obrigatório'),
			document: yup.string().isCpfOrCnpjOptional('Documento inválido').required('Campo obrigatório'),
			email: yup.string().nullable(),
			phone: yup.string().nullable(),
		}),
	});

	React.useEffect(() => {
		(async function () {
			if (!currentSeller) {
				return;
			}

			if (currentSeller) {
				setPixListLoading(true);

				const listSellerPixSalesRequest: InternalListSellerSalesRequest = {
					currentPage: pagination.currentPage,
					limit: pagination.limit,
					sellerId: currentSeller?.id!,
					formPayment: [SaleFormPaymentEnum.Pix],
					type: [InternalListSellerSalesTypeEnum.Online],
					calcTotalAmount: false
				};

				const response = await sellerApi.internalListSellerSales(listSellerPixSalesRequest);

				if (response) {
					setPixSales(response);
					setPixSalesTotalPages(response.total_pages);
				}
			}

			setPixListLoading(false);
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [updateTable, currentSeller, user.token_id, pagination]);

	React.useEffect(() => {
		if (!isModalOpen) {
			setQrcode('');
		}
	}, [isModalOpen]);

	if (!currentSeller) {
		return <BackofficeAlertContainer />;
	}

	return (
		<>
			<Flex pb='8'>
				<Loader isOpen={isLoading} />

				<Breadcrumb separator={<ChevronRightIcon />}>
					<BreadcrumbItem>
						<BreadcrumbLink href='#' fontSize='md'>
							Resumo
						</BreadcrumbLink>
					</BreadcrumbItem>

					<BreadcrumbItem>
						<BreadcrumbLink href='#' fontSize='md'>
							Venda
						</BreadcrumbLink>
					</BreadcrumbItem>

					<BreadcrumbItem isCurrentPage>
						<BreadcrumbLink opacity='0.9' cursor='unset' href='#' fontSize='md' fontWeight={`semibold`}>
							Venda por Pix
						</BreadcrumbLink>
					</BreadcrumbItem>
				</Breadcrumb>
			</Flex>
			<Formik enableReinitialize initialValues={pixInitialValues} validationSchema={schema} onSubmit={handleSubmit}>
				{({ handleSubmit, setFieldValue, resetForm, values, errors, isValid }) => {
					return (
						<form onSubmit={handleSubmit} style={{ width: '100%' }}>
							<Loader isOpen={pixListLoading} />

							<Flex mb='4'>
								<Title fontSize={isMobile ? 'lg' : '2xl'}>VENDA POR PIX</Title>
							</Flex>

							<Flex flexDirection={`column`}>
								<Flex
									flexDir={`column`}
									p={10}
									bgColor='white'
									rounded={`md`}
									boxShadow={`sm`}
									mt='2'
									w='100%'
									className='form__main-infos'
								>
									<Stack direction={isMobile ? 'column' : 'row'} spacing={4} mb='4'>
										<FormControl isRequired>
											<FormLabel fontSize={`md`} fontWeight={`medium`}>
												Valor Total do Serviço
											</FormLabel>
											<FormDebouncedInput
												isDisabled={isLoading}
												name='amount'
												value={showAmount}
												placeholder='"Ex.: 123,52+64,32+..."'
												onChange={(event) => {
													try {
														const filteredValue = event.target.value.replace(/,/gi, '.');

														setShowAmount(filteredValue);
														const totalValue = Number(math.evaluate(filteredValue));

														setAmount(totalValue.toString());
													} catch (err) {}
												}}
												afterDebounce={async () => {
													const amountCents = Number(amount) * 100;

													if (isNaN(amountCents)) {
														return;
													}
													setIsLoading(true);

													const SimulationRequest: ListSimulationRequest = {
														sellerId: currentSeller?.id!,
														amountCents: Number(amountCents),
													};

													const response = await simulationApi.listSimulation(SimulationRequest);
													const simulationPix = response.find((installment) => installment.type === 'pix');
													setFieldValue('amount_cents', simulationPix?.total_amount_cents ? simulationPix?.total_amount_cents / 100 : 0);
													setIsLoading(false);
												}}
											/>
											<FormErrorMessage name='amount' />
										</FormControl>

										<FormControl>
											<FormLabel fontSize={`md`} fontWeight={`medium`}>
												Valor Total Com Taxa
											</FormLabel>
											<FormCurrencyInput isDisabled name='amount_cents' borderColor='darkGrey' fontSize={`md`} fontWeight={`medium`} />
										</FormControl>

										<FormControl m={1} isRequired>
											<FormLabel fontSize={`md`} textAlign={'justify'} fontWeight={`medium`}>
												Descrição
											</FormLabel>
											<FormInput name='description' fontSize={`md`} fontWeight={`medium`} />
											<FormErrorMessage name='description' />
										</FormControl>

										<FormControl m={1} isRequired>
											<FormLabel fontSize={`md`} fontWeight={`medium`}>
												Data de Vencimento
											</FormLabel>
											<FormDatePickerInput
												name='deliveryDate'
												placeholder={`Informe o período`}
												minDate={new Date()}
												borderColor='darkGrey'
												fontSize={`md`}
												fontWeight={`medium`}
											/>
											<FormErrorMessage name='deliveryDate' />
										</FormControl>
									</Stack>
									<Stack direction={isMobile ? 'column' : 'row'} spacing='4' mb='4'>
										<PayerForm
											onSearchResults={(data) => {
												setFieldValue('payer.name', data.name);
												setFieldValue('payer.document', data.document);
												setFieldValue('payer.email', data.email);
												setFieldValue('payer.phone', data.phone);
											}}
											setSelectedPayer={() => {
												return { name: '', document: '' };
											}}
											errors={errors}
											requiredFields={{
												document: true,
												name: true,
												email: false,
												phone: false,
												address: {
													zipcode: false,
													street: false,
													district: false,
													city: false,
													state: false,
													number: false,
												},
											}}
											columnsNumber={4}
										/>
									</Stack>

									<FormControl display='flex' justifyContent={isMobile ? 'center' : 'flex-end'} mt='4'>
										<Button
											size='lg'
											type='submit'
											disabled={!isValid || isSubmitting}
											bgColor={'primary'}
											isLoading={isSubmitting}
											className='button__generate-pix'
										>
											Gerar Pix
										</Button>
									</FormControl>
								</Flex>

								{currentSeller?.has_split_rules && (
									<Paper>
										<FormControl display='flex' alignItems='center'>
											<FormLabel htmlFor='email-alerts' mb='0' color='primary' fontWeight='bold'>
												<Text fontSize={`md`} fontWeight={`medium`}>
													Dividir valor da venda?
												</Text>
											</FormLabel>
											<Switch
												id='payer-data'
												isChecked={hasSplitRules}
												onChange={() => {
													setHasSplitRules(!hasSplitRules);
													setFieldValue('hasSplitRules', !hasSplitRules);
												}}
												name='hasSplitRules'
											/>
										</FormControl>
										{hasSplitRules && <SplitRulesWrapper splits={splits} setSplits={setSplits} />}
									</Paper>
								)}

								{pixSales && pixSales.results && (
									<>
										<Flex mt='8'>
											<Title>Pix Gerados</Title>
										</Flex>
										<Flex flexDir={`column`} p={10} bgColor='white' rounded={`md`} boxShadow={`sm`} mt='2' w='100%'>
											<Flex overflowX='auto'>
												<PixTable
													onRowClick={(row) => navigateToDetails(row.original.id)}
													data={pixSales}
													setPagination={setPagination}
													pagination={pagination}
													totalPages={pixSalesTotalPages}
													setIsModalOpen={setIsModalOpen}
													setQrcode={setQrcode}
												/>
											</Flex>
										</Flex>
									</>
								)}
							</Flex>

							<Modal
								isOpen={isModalOpen}
								setIsModalOpen={setIsModalOpen}
								isLoading={isLoading}
								setIsLoading={setIsLoading}
								seller={currentSeller}
								qrcode={qrcode}
								resetForm={resetForm}
							/>
						</form>
					);
				}}
			</Formik>
		</>
	);
};

export default PixSale;
